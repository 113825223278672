import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import slider1 from "../../../assets/img/banner/slider1.jpg";
import slider2 from "../../../assets/img/banner/slider33.jpg";
import slider3 from "../../../assets/img/banner/flat-lay.jpg";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
import { useTranslation } from "react-i18next";
const bannerData = bannerDB;
function Banner() {
  const { t } = useTranslation();

  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection d-none">
        <div className="container-fluid">
          <Slider {...settings}>
            {data &&
              data.map((item) => {
                return (
                  item?.image?.url && (
                    <Link to={item?.url || ""} key={item._id}>
                      <div
                        className="bannerItem"
                        // style={!isMobile ? { height: "500px" } : {}}
                      >
                        <img
                          src={item?.image.url}
                          // className="aspect-ratio-img"
                        />
                      </div>
                    </Link>
                  )
                );
              })}
          </Slider>
        </div>
      </section>

      <section className="bannerSection">
        <Slider {...settings}>
          <div className="bannerItem">
            <Link to={"/"}>
              <img
                className="img-fluid"
                src={slider1}
                alt="Al-Saniya Food Stuff Trading LLC"
              />
              <div className="bannerText">
                <h6>{t("Alsaniya Foodstuff trading")}</h6>
                <h2>
                  {t("Fresh")} <span>{t("Flavour")}</span>, {t("Delivered to you")}
                </h2>
                <p>
                  {t("We bring you the freshest and highest quality flavour from arround the world, delivered right to your doorstep")}
                </p>
                <Link to="/">{t("Get a quote")}</Link>
              </div>
            </Link>
          </div>
          <div className="bannerItem">
            <Link to={"/"}>
              <img
                className="img-fluid"
                src={slider2}
                alt="Al-Saniya Food Stuff Trading LLC"
              />
              <div className="bannerText">
                <h6>{t("We Provide")}</h6>
                <h2>{t("We are Channel Partners of Lactalis, Prabhat Dairy")}</h2>
                <p>
                  {t("We are Channel Partners PARA​")}
                </p>
                <Link to="/">{t("Get a quote")}</Link>
                
              </div>
            </Link>
          </div>
          <div className="bannerItem">
            <Link to={"/"}>
              <img
                className="img-fluid"
                src={slider3}
                alt="Al-Saniya Food Stuff Trading LLC"
              />
              <div className="bannerText">
                <h6>{t("Alsaniya Foodstuff trading")}</h6>
                <h2>
                  {t("Fresh")} <span>{t("Vegetables")}</span>, {t("Delivered to you")}
                </h2>
                <p>
                  {t("We bring you the freshest and highest quality flavour from arround the world, delivered right to your doorstep")}
                </p>
                <Link to="/">{t("Get a quote")}</Link>
              </div>
            </Link>
          </div>
        </Slider>
      </section>
    </>
  );
}

export default Banner;
