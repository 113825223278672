import React from "react";
import etg from "../../../assets/img/brands/brand1.PNG";
import kynoch from "../../../assets/img/brands/brand2.PNG";
import falcon from "../../../assets/img/brands/brand3.PNG";
import oemff from "../../../assets/img/brands/brand4.PNG";
import artboard from "../../../assets/img/brands/brand5.PNG";
import artboard6 from "../../../assets/img/brands/brand6.png";
import artboard7 from "../../../assets/img/brands/brand7.png";
import artboard8 from "../../../assets/img/brands/brand8.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OurBrand = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="brandsSec">
        <div className="container">
          <div className="brandsHeader">
            <div className="fisherman-content">
              <h3>{t("Our brands")}</h3>
            </div>
          </div>
          <div className="brandsItem">
            <ul>
              <li>
                <Link to={"/products"}>
                  <img src={etg} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={falcon} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={kynoch} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={oemff} alt="Brand" title="Brand" />
                </Link>
              </li>

              <li>
                <Link to={"/products"}>
                  <img src={artboard} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={artboard6} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={artboard7} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/products"}>
                  <img src={artboard8} alt="Brand" title="Brand" />
                </Link>
              </li>
              
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBrand;
