import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestDeals from "./best-deals/BestDeals";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";

import slider1 from "../../assets/img/flat-lay.jpg";
import slider2 from "../../assets/img/flat-lay1.jpg";
import country_map from "../../assets/img/country_map.jpg";

import bio1 from "../../assets/img/bio1.jpg";
import bio2 from "../../assets/img/bio2.jpg";
import bio3 from "../../assets/img/dairy1.png";
import bio4 from "../../assets/img/dairy2.png";

import bio5 from "../../assets/img/bio5.jpg";
import bio6 from "../../assets/img/bio6.jpg";
import bio7 from "../../assets/img/bio7.jpg";
import bio8 from "../../assets/img/bio8.jpg";

import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import { base_url } from "../../server";
import PopularProduct from "./popular";
import { Link } from "react-router-dom";
import NotifyItem from "./notify-item/NotifyItem";
import Testimonial from "./testimonial/Testimonial";
import OurBrand from "./our-brand/OurBrand";
import OurClient from "./our-client/OurClient";
import { useTranslation } from "react-i18next";
import Provide from "./provide/Provide";
import VibrantVeg from "./vibrant-veg/VibrantVeg";
function Home() {
  const productData = useContext(productItemHome);
  const { t } = useTranslation();
  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const getata = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter/categ`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getata();
  }, []);
  return (
    <>
      <Banner />

      <ProductsCategory />

      {data &&
        data?.slice(0, 3).map((item, i) => {
          return <CatagProduct key={i} item={item} />;
        })}
      {/* <Products productData={productData} /> */}
      <ServiceList />
      <div className="singleBanner">
        <div className="container-fluid">
          <div className="bannerItem">
            <Link to={"/"}>
              <img src={slider1} alt="Al-Saniya Food Stuff Trading LLC" />
              <div className="bannerText">
                <h2>{t("Stay home & get your daily needs from our shop")}</h2>
                <p> {t("We Produce best quality.")}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="paperBag pt-4 pb-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>{t("dairy and paper bags")}</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio1} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio2} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio3} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio4} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="paperBag pt-4 pb-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>{t("chutneys pickles and sauces")}</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio5} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio6} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio7} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="paperBagItem">
                <Link to={"/products"}>
                  <img src={bio8} alt="Al-Saniya Food Stuff Trading LLC" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FeaturedProducts /> */}
      {/* <NotifyItem /> */}
      <PopularProduct />
      <VibrantVeg />
      <BestDeals />
      <Provide />
      <div className="singleBanner">
        <div className="bannerItem">
          <Link to={"/"}>
            <img src={slider2} alt="Al-Saniya Food Stuff Trading LLC" />
            <div className="bannerText">
              <h2 className="text-white">
                {t("vegetables good for your health")}
              </h2>
              <p className="text-white"> {t("We Produced best quality.")}</p>
              <span className="btn">Order Now</span>
            </div>
          </Link>
        </div>
      </div>

      <Testimonial />
      <OurBrand />

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <OurClient />
      <div className="singleBanner pb-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="fisherman-content">
                <h3>Country Presence</h3>
                
              </div>
              <div className="countryMapList">

                <ul>
                  <li>Argentina</li>
                  <li>Africa</li>
                  <li>Congo</li>
                  <li>Jordon</li>
                  <li>Saudi Arabia</li>
                  <li>Russia</li>
                  <li>France</li>
                  <li>Lithuania</li>
                  <li>Iran</li>
                  <li>Oman</li>
                  <li>India</li>
                  <li>Pakistan</li>
                  <li>Srilanka</li>
                  <li>Iraq</li>
                </ul>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="countryMap">
                <img src={country_map} alt="Al-Saniya Food Stuff Trading LLC" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Brands /> */}
      {/* <BestSeller /> */}
      {/* <Network /> */}
      {/* <AddingShow /> */}

      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
    </>
  );
}

export default Home;
