import React from "react";
import { Link } from "react-router-dom";
import bestDeal from "../../../../assets/img/banner-4.png";
import { useTranslation } from "react-i18next";

const CountDown = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="notifyCard">
        <img src={bestDeal} alt="bestDeal" className="img-fluid" />
        <div className="notifyCardText">
          <h3>{t("Bring fresh products into your home")}</h3>
          <Link to="/">Shop Now </Link>
        </div>
      </div>
    </>
  );
};

export default CountDown;
