import React from 'react';
import mile from "../../../assets/img/brands/milestone.PNG";
import { useTranslation } from 'react-i18next';


const Milestone = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
       <section className="milestoneSec">
        <div className="container-fluid">
          <h3 className="subtitle">{t('Our Milestone')}</h3>
          <div className="row">
            <div className="col-lg-4">
              <div className="mileStoneImg">
              <img src={mile} alt="Brand" title="Brand" />

              </div>
            </div>
            <div className="col-lg-8">
              <div className="featureBox">
                <ul>
                  <li>
                  <span className='year'>2021</span>
                  <p>{t("Export 1800 MT of dairy products")}</p>
                  </li>
                  <li>
                  <span className='year'>2022</span>
                  <p>{t("Export 2200 MT of dairy products")}</p>
                  </li>
                  <li>
                  <span className='year'>2023</span>
                  <p>{t("Export 2800 MT of dairy products")}</p>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
         
        </div>
        
      </section>
    </>
  )
}

export default Milestone