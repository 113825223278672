import React from "react";
import { Link } from "react-router-dom";

import { MdOutlineAdminPanelSettings, MdPhoneInTalk } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useGetBlogsQuery } from "../../products/productSlice";

import blog1 from "../../../assets/img/serviceList/cagliata.jpg";
import blog2 from "../../../assets/img/serviceList/Indian-Cereals.jpg";
import blog3 from "../../../assets/img/serviceList/Indian-Pulses.jpg";
import blog4 from "../../../assets/img/serviceList/gr5ikknbtygma4krpfai.png";
import blog5 from "../../../assets/img/serviceList/f454dq2nqgkt45dcladv.png";
import blog6 from "../../../assets/img/serviceList/jo1im9jkpkbst0of9sti.png";

const newsArray = [
  {
    id: 1,
    date: "02 Apr",
    url: blog1,
    shortInfo:
      "Cagliata is a soft, semi-finished cheese made from pasteurized cow's milk and rennet..",
    title: "Cagliata",
    desc: "Cagliata is a soft, semi-finished cheese made from pasteurized cow's milk and rennet, with a mild, slightly acidic flavor, Appearance: Cagliata has a soft, elastic flesh that's white to pale yellow in color. Taste: Cagliata has a mild, lactic, and slightly bitter flavor. Uses: Cagliata is used as an intermediate product in the production of other cheeses, such as Mozzarella and Ricotta. It can also be eaten on its own or used in the food and bakery industry. Production: Cagliata is made by coagulating pasteurized milk with microbial rennet, which curdles the fat. The resulting curd is dried and formed into blocks, which are then dipped in a salt solution",
    link: "Read more",
  },
  {
    id: 2,
    date: "02 Apr",
    url: blog2,
    category: "Blog",
    shortInfo:
      "Incorporating whole grains into your diet can have a profound impact on your health.",
    title: "Grains",
    desc: "Incorporating whole grains into your diet can have a profound impact on your health. From promoting digestive health to reducing the risk of chronic diseases, grains provide a range of benefits that make them an essential part of a balanced diet. Aim to choose whole grains, such as brown rice, quinoa, oats, and barley, over refined grains for maximum health benefits.",
    link: "Read more",
  },
  {
    id: 3,
    date: "02 Apr",
    url: blog3,
    category: "Blog",
    shortInfo: "I got my first premium designer bag when I was...",
    title: "Do you really understand yourself?",
    desc: "I got my first premium designer bag when I was in middle school. It was something I wished for, pined for, dreamed for and worked hard for.",
    link: "Read more",
  },
  {
    id: 4,
    date: "02 Apr",
    url: blog4,
    shortInfo:
      "The Amazing Health Benefits of Broccoli: Why You Should Add It to Your Plate",
    title: "brocolli",
    desc: "Broccoli is a nutrient powerhouse, packed with vitamins, minerals, and antioxidants that offer numerous health benefits. This green veggie is incredibly versatile, making it easy to incorporate into a variety of dishes. Here’s why broccoli should be a regular addition to your diet, Rich in Nutrients and Low in Calories Broccoli is nutrient-dense yet low in calories, making it ideal for those looking to maintain or lose weight while still getting essential nutrients. It’s a great source of fiber, vitamins C and K, folate, potassium, and even a bit of protein.",
    link: "Read more",
  },
  {
    id: 5,
    date: "02 Apr",
    url: blog5,
    category: "Blog",
    shortInfo:
      "Celery is a low-calorie vegetable with a crisp texture and a wide range of health benefits",
    title: "Celery",
    desc: "Celery is a low-calorie vegetable with a crisp texture and a wide range of health benefits. Packed with vitamins, minerals, antioxidants, and fiber, celery can support various aspects of health, from digestion to heart health. Here’s why celery should be a regular part of your diet, 1. Rich in Antioxidants Celery contains antioxidants like vitamin C, beta carotene, and flavonoids that protect cells from oxidative damage. These antioxidants can help reduce inflammation, support immune function, and may lower the risk of chronic diseases like heart disease and cancer.",
    link: "Read more",
  },
  {
    id: 6,
    date: "02 Apr",
    url: blog6,
    category: "Blog",
    shortInfo:
      "Parsley is often used as a decorative garnish, but this vibrant herb has much more to offer than...",
    title: "parsley",
    desc: "Parsley is often used as a decorative garnish, but this vibrant herb has much more to offer than aesthetic appeal. Packed with vitamins, minerals, and powerful antioxidants, parsley can boost your health in numerous ways. Here are some of the top benefits of adding parsley to your diet, 1. Rich Source of Essential Nutrients Parsley is loaded with vitamins A, C, and K, as well as folate, iron, and potassium. These nutrients are essential for various bodily functions, from supporting vision and immune health to promoting bone strength and heart health.",
    link: "Read more",
  },
];
function BlogItem() {
  const { data, isLoading } = useGetBlogsQuery();
  return (
    <>
      <section className="blogItemSec">
        <div className="row">
          {isLoading && (
            <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {newsArray.map((item) => {
            return (
              <div key={item.id} className="col-lg-4">
                <div className="blogItemInfo">
                  <div className="blogItemFigure">
                    <img src={item.url} className="img-fluid" alt="Blog" />
                    <div className="postDate">
                      <span className="date">25</span>
                      <span className="month">feb</span>
                    </div>
                  </div>
                  <div className="blogItemContent">
                    <div className="author">
                      <ul>
                        <li>
                          {/* <Link to={`/blog-detail/${item.uid}/${item.slug}`}> */}
                          <Link to={`/blog-detail/${item.id}`}>
                            <MdOutlineAdminPanelSettings />
                            By Ismail
                          </Link>
                        </li>
                        <li>
                          {/* <Link to={`/blog-detail/${item.uid}/${item.slug}}`}> */}
                          <Link to={`/blog-detail/${item.id}`}>
                            {" "}
                            <MdPhoneInTalk /> Consulting
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="title">
                      <h4>
                        {/* <Link to={`/blog-detail/${item.uid}/${item.slug}`}> */}
                        <Link to={`/blog-detail/${item.id}`}>{item.title}</Link>
                      </h4>
                      <p>{item.shortInfo}</p>
                    </div>
                    <div className="readMore">
                      <Link to={`/blog-detail/${item.id}`}>
                        {/* <Link to={`/blog-detail/${item.uid}/${item.slug}`}> */}
                        Read More <HiOutlineArrowNarrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* {data &&
            data.map((item) => {
              return (
                <div key={item._id} className="col-lg-6">
                  <div className="blogItemInfo">
                    <div className="blogItemFigure">
                      <img
                        src={item.banner.url}
                        style={{ height: "400px" }}
                        alt="Blog"
                      />
                      <div className="postDate">
                        <span className="date">25</span>
                        <span className="month">feb</span>
                      </div>
                    </div>
                    <div className="blogItemContent">
                      <div className="author">
                        <ul>
                          <li>
                            <Link to={`/blog-detail/${item.uid}/${item.slug}`}>
                              <MdOutlineAdminPanelSettings />
                              By Ismail
                            </Link>
                          </li>
                          <li>
                            <Link to={`/blog-detail/${item.uid}/${item.slug}}`}>
                              {" "}
                              <MdPhoneInTalk /> Consulting
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="title">
                        <h4>
                          <Link to={`/blog-detail/${item.uid}/${item.slug}`}>
                            {item.title}
                          </Link>
                        </h4>
                        <p>{item.short_description}</p>
                      </div>
                      <div className="readMore">
                        <Link to={`/blog-detail/${item.uid}/${item.slug}`}>
                          Read More <HiOutlineArrowNarrowRight />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
        </div>
      </section>
    </>
  );
}

export default BlogItem;
