import React from "react";
import vib1 from "../../../assets/img/vib1.jpg";
import vib2 from "../../../assets/img/vib2.jpg";
import vib3 from "../../../assets/img/vib3.jpg";
import vib4 from "../../../assets/img/vib4.jpg";
import { Link } from "react-router-dom";

const VibrantVeg = () => {
  return (
    <>
      <div className="vibrantSec pt-3 pb-3">
        <div className="container-fluid">
          <div className="vibrantCard">
            <div className="vibrantItem">
              <Link to="/products">
                <img src={vib1} alt="vibrant" className="img-fluid" />
                <h3>Al-Saniya</h3>
              </Link>
            </div>
            <div className="vibrantItem">
              <Link to="/products">
                <img src={vib2} alt="vibrant" className="img-fluid" />
                <h3>Al-Saniya</h3>
              </Link>
            </div>
            <div className="vibrantItem">
              <Link to="/products">
                <img src={vib3} alt="vibrant" className="img-fluid" />
                <h3>Al-Saniya</h3>
              </Link>
            </div>
            <div className="vibrantItem">
              <Link to="/products">
                <img src={vib4} alt="vibrant" className="img-fluid" />
                <h3>Al-Saniya</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VibrantVeg;
