import React from "react";
import { useTranslation } from "react-i18next";

import v7g6gowiju0wanpwx70f from '../../../assets/img/v7g6gowiju0wanpwx70f.jpg'
import { TiTick } from "react-icons/ti";
function AboutStory({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="aboutSection pt-5 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="aboutCard">
                <h3 className="subtitle">{t("About Us")}</h3>
                {/* <p className="">{data?.about_company}</p> */}
                <p>{t("about-1")}</p>
                <p>{t("about1")}</p>
                <p>{t("about2")}</p>
                <p>{t("about3")}</p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                src={v7g6gowiju0wanpwx70f}
                alt="v7g6gowiju0wanpwx70f"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
