import React from "react";
import Rating from "../../../shared/rating/Rating";
import user2 from "../../../assets/img/Gazebo-logo-new-1.webp";
import user1 from "../../../assets/img/Main_Logo.webp";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
const settings = {
  speed: 500,
  slidesToShow: 1, // Number of items to show on desktop
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024, // Screen size for tablets
      settings: {
        slidesToShow: 1, // Number of items to show on tablets
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen size for mobile devices
      settings: {
        slidesToShow: 1, // Number of items to show on mobile
        slidesToScroll: 1,
        // arrows: false,
      },
    },
  ],
};
const Testimonial = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="testimonial">
        <div className="container-fluid">
          <div className="testTitle">
            <h6>{t("Customer Reviews")}</h6>
            <h2>{t("Our Happy Customers")}</h2>
          </div>
          <Slider {...settings}>
            <div className="testimonialCard">
              <div className="row">
                <div className="col-md-3">
                  <div className="testFigure">
                    <img src={user2} alt="Theodore Handle" />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="testText">
                    <Rating />
                    <p>
                      {t(
                        "We’re impressed with AL-SANIYA FOOD STUFF TRADING LLC commitment to ethical sourcing and sustainable practices. Knowing that we’re working with a company that cares about the environment and fair trade is important to us and our customers. Keep up the great work!"
                      )}
                    </p>
                    <h5 className="user">{t("FMCG Manager ")}</h5>
                    <span className="profile">{t("Gazebo restaurant")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonialCard">
              <div className="row">
                <div className="col-md-3">
                  <div className="testFigure">
                    <img src={user1} alt="Kate Jonson" />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="testText">
                    <Rating />
                    <p>
                      {t(
                        "We appreciate the wide range of products offered by AL-SANIYA FOOD STUFF TRADING LLC. They have everything we need for our store, from organic grains to unique spices. Our customers love the variety, and it’s been great for business. Highly recommend them to anyone in the food industry!"
                      )}
                    </p>
                    <h5 className="user">{t("Admin ")}</h5>
                    <span className="profile">{t("Baskin Robbins")}</span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
