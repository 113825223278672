import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "../../components/contact/Contact";

function ContactPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>Contact Us | Al-Saniya Food Stuff Trading LLC</title>
        <meta name="keyword" content="Al-Saniya Food Stuff Trading LLC" />
        <meta name="description" content="Al-Saniya Food Stuff Trading LLC" />
      </Helmet>
      <Contact />
    </>
  );
}

export default ContactPage;
